import baseURL from "@/utils/config"
import request from "@/plugins/axios"
const langEnv = window.localStorage.getItem("langEnv")
// 首页数据
export function getIndexRith (data) {
  return request({
    url: `${baseURL.api}/api/indexRith`,
    method: "post",
    data,
  })
}
// 首页推广数据
export function getExtendPhone (data) {
  return request({
    url: `${baseURL.api}/api/index_extendPhone`,
    method: "post",
    data,
  })
}
// 精品推荐数据
export function getRecommend (data) {
  return request({
    url: `${baseURL.api}/api/index-recommend`,
    method: "post",
    data,
  })
}
