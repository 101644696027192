import baseURL from "@/utils/config"
import request from "@/plugins/axios"
const langEnv = window.localStorage.getItem("langEnv")
// 首页数据
export function gethotWord (data) {
  return request({
    url: `${baseURL.api}/api/indexNew-hotWord`,
    method: "post",
    data,
  })
}
// 商品列表
export function getProductList (data) {
  return request({
    url: `${baseURL.api}/api/product/list4phone`,
    method: "post",
    data,
  })
}

